<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-3">
            <CCol md="12" class="p-0">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 150px;">
                    <h2 class="text-center text-white middle-center"><b>Pusat Bantuan</b></h2>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12">
                    <CCard class="">
                        <CCardHeader>
                            <h2 class="text-center"><b>Informasi & Unduh File</b></h2>
                        </CCardHeader>
                        <CCardBody>
                            <div class="row">
                                <img src="img/bg-download.png" style="position: absolute; opacity: 0.7; right: 15px;" />
                                <div class="col-md-6">
                                    <table class="table table-borderless table-sm" style="width: 100%; position: relative;">
                                        <tbody>
                                            <tr>
                                                <td><label for="">Jenis Tanaman Yang Terdapat Dalam Kepmentan 104 Tahun 2020</label></td>
                                                <td style="width: 100px;"><div class="btn btn-info btn-sm pull-right btn-block" v-on:click="openModalTanaman"><i class="fa fa-eye"></i> Lihat</div></td>
                                            </tr>
                                            <!-- <tr>
                                                <td><label for="">Data Aropt</label></td>
                                                <td><div class="btn btn-info btn-sm pull-right btn-block" v-on:click="openModalAropt"><i class="fa fa-eye"></i> Lihat</div></td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td><label for="">Data AROPT</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/AROPT per April 2021.xls" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td><label for="">Permentan No 15-2017-Pemasukan dan Pengeluaran Benih</label></td>
                                                <td>
                                                    <a
                                                        class="btn btn-info btn-sm pull-right btn-block"
                                                        href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Permentan%20No%2015-2017-Pemasukan%20dan%20Pengeluaran%20Benih%20Hortikulturaa.pdf"
                                                        target="_blank"
                                                    >
                                                        <i class="fa fa-save"></i> Download
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Permentan No 45 tahun 2019</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Permentan 45 2019.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form Surat Pernyataan Jenis Tanaman Yang Tidak Terdapat Dalam Kepmentan 104 Tahun 2020</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Form Pernyataan Jenis tanaman yg tidak terdapat di kepmentan 104 2020.docx" target="_blank">
                                                        <i class="fa fa-save"></i> Download
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form IK-01 Surat Permohonan Pengeluaran Benih Hortikultura</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Form IK.01 Surat Permohonan Pengeluaran.docx" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form IM-01 Surat Permohonan Pemasukan Benih Hortikultura</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Form IM.01 Surat Permohonan Pemasukan.docx" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">KBLI Perusahaan</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/kbli_perusahaan.xls" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Paparan Sosialisasi Aplikasi Benih Tanaman (Admin PPVTPP)</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Paparan Sosialisasi Aplikasi Benih Tanaman (Admin PPVTPP).ppt" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Paparan Sosialisasi Aplikasi Benih Tanaman</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Paparan_Sosialisasi_Aplikasi_Benih_Tanaman.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Panduan Integrasi Data Perusahaan / Perorangan</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/Panduan_Integrasi_Data_Perusahaan_Atau_Perorangan.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Surat Pemberitahuan Formulir Informasi</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/kelengkapan%20dokumen%20izin%20ekspor.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form Informasi Tanaman Yang Akan Dikeluarkan</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20Informasi%20Tanaman%20Yang%20Akan%20Dikeluarkan.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="table table-borderless table-sm" style="width: 100%; position: relative;">
                                        <tbody>
                                            
                                            <tr>
                                                <td><label for="">Form IF-01 Information Required For Seed Introduction</label></td>
                                                <td style="width: 100px;">
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20Inform%20Seed%20IF.01.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form IF-02 NPPO</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20NPPO%20IF.02.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form Surat Pernyataan Kebenaran Dokumen</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20Surat%20Pernyataan%20Kebenaran%20Dokumen.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form Laporan Realisasi Untuk SIP yang Sudah Diterbitkan</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/FORM%20LAPORAN%20REALISASI%20&%20NILAI%20USD.xlsx" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Permentan 15 Tahun 2017</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Permentan%20No%2015-2017-Pemasukan%20dan%20Pengeluaran%20Benih%20Hortikulturaa.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Permentan 45 Tahun 2019</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Permentan%2045%202019.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form PROPOSAL PENGEMBANGAN RENCANA TANAM</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20PROPOSAL%20PENGEMBANGAN%20RENCANA%20TANAM.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form Surat Pernyataan Yang Berhak Bertanda Tangan</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20Surat%20Pernyataan%20Yang%20Berhak%20Bertanda%20Tangan.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">Form Surat Pernyataan Tidak Diperjualbelikan (Perorangan)</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="https://ap1.pertanian.go.id/simppi_v3/assets/files/Form%20Surat%20Pernyataan%20Tidak%20Diperjualbelikan%20(Perorangan).pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">KEBIJAKAN EKSPOR KARANTINA TUMBUHAN</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/KEBIJAKAN EKSPOR KARANTINA TUMBUHAN.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">KEBIJAKAN PENGELUARAN BENIH HORTIKULTURA</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/KEBIJAKAN PENGELUARAN BENIH HORTIKULTURA.pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label for="">B-8073 Larangan ekspor ke Jepang dan Korsel (30 April 2021)</label></td>
                                                <td>
                                                    <a class="btn btn-info btn-sm pull-right btn-block" href="assets-download/B-8073 Larangan ekspor ke Jepang dan Korsel (30 April 2021).pdf" target="_blank"><i class="fa fa-save"></i> Download</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h4 class="my-0 text-center"><b>FAQ</b></h4>
                        </CCardHeader>
                        <CCardBody>
                            <div>
                                <CCard class="mb-0 a-black" v-for="(val_tipe_faq, key_tipe_faq) in tipe_faq" v-bind:key="key_tipe_faq" style="color: black"> 
                                    <CButton
                                        block
                                        color="link"
                                        class="text-left shadow-none card-header bg-success text-white"
                                        @click="accordion = accordion === key_tipe_faq ? false : key_tipe_faq"
                                    >
                                        <h5 class="m-0 no-underline">{{key_tipe_faq+1}}. {{val_tipe_faq.tipe_faq_nama}}</h5>
                                    </CButton>
                                    <CCollapse :show="accordion === key_tipe_faq">
                                        <CCardBody>
                                            <ol>
                                                <li v-for="(faq_value, faq_key) in val_tipe_faq.faq" :key="faq_key">
                                                    <router-link :to="{name: 'JawabanFaq', params: { idFaq: faq_value.id }}" >
                                                        {{faq_value.faq_pertanyaan}}
                                                    </router-link>
                                                </li>
                                            </ol>
                                        </CCardBody>
                                    </CCollapse>
                                </CCard>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol md="12" class="mb-3">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <router-link to="/pemohon/dashboardpemohon" class="btn btn-warning btn-block text-white btn-hover-outline-warning btn-lg">
                                <i class="fa fa-chevron-left"></i>Kembali
                            </router-link>
                        </div>
                    </div>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
    const null_array = [];
    export default {
        name: "PusatBantuan",
        data() {
            return {
                accordion: 0,
                tipe_faq: null_array
            };
        },
        methods: {
            openModalTanaman: function(){
                let self = this;
                this.searchData_tanaman = '';
                this.modalTanaman = true;
                self.getTanaman();
            },
            openModalAropt: function(){
                let self = this;
                this.searchData_aropt = '';
                this.modalAropt = true;
                self.getAropt();
            },
            redirectDashboard: function () {
                if (this.session.roles == "PEMOHON") {
                    this.$router.push({ name: "DashboardPemohon" });
                } else {
                    this.$router.push({ name: "Dashboard" });
                }
            },
            toPageTanaman(url) {
                this.loadingTable_tanaman = true;
                axios.get(url)
                .then((response) => {
                    var res_tanaman = response.data;
                    var res_tanaman_data = res_tanaman.data[0];
                    // console.log(res_tanaman_data);
                    if (res_tanaman.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_tanaman.data.message
                        });
                    }
                    else{
                        this.loadingTable_tanaman = false;
                        this.dataintable_tanaman = res_tanaman_data.data;
                        this.paging_tanaman = res_tanaman_data.links;
                        this.show_data_from_tanaman = res_tanaman_data.from;
                        this.show_data_to_tanaman = res_tanaman_data.to;
                        this.total_data_tanaman = res_tanaman_data.total;
                        this.curent_page_tanaman = res_tanaman_data.current_page;
                    }
                });
            },
            searchTanaman() {
                this.loadingTable_tanaman = true;
                axios.get(this.apiLink + "api/crud/tanaman", {
                    params: {
                        search: this.searchData_tanaman,
                    }
                })
                .then((response) => {
                    var res_tanaman = response.data;
                    var res_tanaman_data = res_tanaman.data[0];
                    // console.log(res_tanaman_data);
                    if (res_tanaman.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_tanaman.data.message
                        });
                    }
                    else{
                        this.loadingTable_tanaman = false;
                        this.dataintable_tanaman = res_tanaman_data.data;
                        this.paging_tanaman = res_tanaman_data.links;
                        this.show_data_from_tanaman = res_tanaman_data.from;
                        this.show_data_to_tanaman = res_tanaman_data.to;
                        this.total_data_tanaman = res_tanaman_data.total;
                        this.curent_page_tanaman = res_tanaman_data.current_page;
                    }
                });
            },
            getTanaman() {
                this.loadingTable_tanaman = true;
                axios.get(this.apiLink + "api/crud/tanaman")
                .then((response) => {
                    var res_tanaman = response.data;
                    var res_tanaman_data = res_tanaman.data[0];
                    // console.log(res_tanaman_data);
                    if (res_tanaman.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_tanaman.data.message
                        });
                    }
                    else{
                        this.loadingTable_tanaman = false;
                        this.dataintable_tanaman = res_tanaman_data.data;
                        this.paging_tanaman = res_tanaman_data.links;
                        this.show_data_from_tanaman = res_tanaman_data.from;
                        this.show_data_to_tanaman = res_tanaman_data.to;
                        this.total_data_tanaman = res_tanaman_data.total;
                        this.curent_page_tanaman = res_tanaman_data.current_page;
                    }
                });
            },
            toPageAropt(url) {
                this.loadingTable_aropt = true;
                axios.get(url)
                .then((response) => {
                    var res_aropt = response.data;
                    var res_aropt_data = res_aropt.data[0];
                    // console.log(res_aropt_data);
                    if (res_aropt.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_aropt.data.message
                        });
                    }
                    else{
                        this.loadingTable_aropt = false;
                        this.dataintable_aropt = res_aropt_data.data;
                        this.paging_aropt = res_aropt_data.links;
                        this.show_data_from_aropt = res_aropt_data.from;
                        this.show_data_to_aropt = res_aropt_data.to;
                        this.total_data_aropt = res_aropt_data.total;
                        this.curent_page_aropt = res_aropt_data.current_page;
                    }
                });
            },
            searchAropt() {
                this.loadingTable_aropt = true;
                axios.get(this.apiLink + "api/crud/aropt", {
                    params: {
                        search: this.searchData_aropt,
                    }
                })
                .then((response) => {
                    var res_aropt = response.data;
                    var res_aropt_data = res_aropt.data[0];
                    // console.log(res_aropt_data);
                    if (res_aropt.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_aropt.data.message
                        });
                    }
                    else{
                        this.loadingTable_aropt = false;
                        this.dataintable_aropt = res_aropt_data.data;
                        this.paging_aropt = res_aropt_data.links;
                        this.show_data_from_aropt = res_aropt_data.from;
                        this.show_data_to_aropt = res_aropt_data.to;
                        this.total_data_aropt = res_aropt_data.total;
                        this.curent_page_aropt = res_aropt_data.current_page;
                    }
                });
            },
            getAropt() {
                this.loadingTable_aropt = true;
                axios.get(this.apiLink + "api/crud/aropt")
                .then((response) => {
                    var res_aropt = response.data;
                    var res_aropt_data = res_aropt.data[0];
                    // console.log(res_aropt_data);
                    if (res_aropt.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_aropt.data.message
                        });
                    }
                    else{
                        this.loadingTable_aropt = false;
                        this.dataintable_aropt = res_aropt_data.data;
                        this.paging_aropt = res_aropt_data.links;
                        this.show_data_from_aropt = res_aropt_data.from;
                        this.show_data_to_aropt = res_aropt_data.to;
                        this.total_data_aropt = res_aropt_data.total;
                        this.curent_page_aropt = res_aropt_data.current_page;
                    }
                });
            },
        },
        created() {
            axios.get(this.apiLink + "api/master/tipe_faq")
            .then((response) => {
                var res_tipe_faq = response.data;
                var res_tipe_faq_data = res_tipe_faq.data;
                if (res_tipe_faq.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_aropt.data.message
                    });
                }
                else{
                    this.tipe_faq = res_tipe_faq_data.master;
                }
            });
        },
    };
</script>